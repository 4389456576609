/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@import '~@atlas/material/atlas-theming';

/* Angular Material Core Theme Configurations */
@include mat-core($atlas-typography);
@include angular-material-theme($atlas-theme);

/* Angular Material Typography */
@include angular-material-typography($atlas-typography);
@include mat-base-typography($atlas-typography);

/* Apply typography to Atlas Custom Components */
@include atlas-components-typography($atlas-typography);

/* Alternate ATLAS themes for Angular Material Components */
@include mat-toolbar-theme($atlas-full-white-theme);

/* ATLAS themes for all Atlas Material Components */
@include atlas-material-theme($atlas-theme);

@include atlas-snackbar-theme();
@include atlas-tooltip-theme();
@include atlas-select-theme($atlas-theme);
@include atlas-tab-theme($atlas-theme);

/* ATLAS Global CSS Classes */
@include atlas-base-classes();
@include atlas-font-globals();

/* Global Styles */
html,
body {
  min-height: 100%;
  line-height: normal;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Sofia';
}

div.mat-datepicker-dialog {
  max-width: 100vw !important;
}
