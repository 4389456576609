/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("Roboto-Light"), local("Roboto-Light"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Light.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Light.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Roboto-Regular"), local("Roboto-Regular"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Regular.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Regular.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("Roboto-Bold"), local("Roboto-Bold"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Bold.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Bold.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("Roboto-Black"), local("Roboto-Black"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Black.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/Roboto-Black.woff") format("woff");
}
@font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("SofiaProLight"), local("SofiaProLight"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProLight.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProLight.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("SofiaProRegular"), local("SofiaProRegular"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProRegular.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProRegular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("SofiaProBold"), local("SofiaProBold"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProBold.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProBold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("SofiaProBlack"), local("SofiaProBlack"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProBlack.woff2") format("woff2"), url("https://cdn.highmark.com/web/assets/fonts/SofiaProBlack.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}